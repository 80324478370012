import { useEffect } from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { getFirestoreCol } from "utils";
import { storage } from "config";
import fileImage from "assets/images/file.png";

export default (route, updatePath) => {
  const collection = getFirestoreCol(route[0]);
  const [value, loading, error] = useCollectionOnce(
    collection.where("path", "==", route.join("/"))
  );
  useEffect(() => {
    var update = true;
    if (!error && !loading) {
      Promise.all([
        getFolders(value.docs, update),
        getFiles(value.docs, update)
      ]).then(([folders, dirFiles]) => {
        const storageRef = storage.ref(route[0]);
        Promise.all(
          dirFiles.map(doc => update && downloadURL(storageRef, doc, update))
        ).then(files => {
          update && updatePath({ folders, files });
        });
      });
    }
    return () => (update = false);
  }, [error, value, loading, updatePath, route]);
  return [loading, error];
};

const getFolders = async (value, update) => {
  if (!update) return [];
  const dirFolders = value.filter(doc => doc.data().type === "folder");
  return (
    update &&
    dirFolders.map(doc => ({
      ...doc.data(),
      key: doc.id,
      date: doc.data().date.toDate()
    }))
  );
};

const getFiles = async (value, update) => {
  if (!update) return [];
  return value.filter(doc => doc.data().type !== "folder");
};

const downloadURL = async (storageRef, doc, update) =>
  update &&
  storageRef
    .child(doc.id.concat(".").concat(doc.data().type))
    .getDownloadURL()
    .then(
      url =>
        update && {
          ...doc.data(),
          key: doc.id,
          date: doc.data().date.toDate(),
          url: url
        }
    )
    .catch(
      err =>
        update && {
          ...doc.data(),
          key: doc.id,
          date: doc.data().date.toDate(),
          url: fileImage
        }
    );
