import React from "react";
import { CardContainer, Heading } from "components/UI";
import Toolbar from "./Toolbar";
import AllFiles from "./AllFiles";
import FilePreview from "./FilePreview";

const FilesView = ({
  header,
  currentUser,
  route,
  restricted,
  maxFolders,
  loading,
  folders,
  files,
  onClickRoute,
  onOpenFolder,
  onOpenFile,
  createFolder,
  deleteFolder,
  uploadFile,
  deleteFile,
  preview,
  folderRef,
  newFolder,
  onChangeName,
  newFiles,
  setNewFiles,
  search,
  setSearch,
  freeEdit,
  moving,
  setMoving,
  acceptMove
}) => (
  <CardContainer
    title={<Heading textAlign="center">{header}</Heading>}
    width={1}
  >
    {route && route.length ? (
      <Toolbar
        currentUser={currentUser}
        route={route}
        maxFolders={maxFolders}
        restricted={restricted}
        onClickRoute={onClickRoute}
        createFolder={createFolder}
        uploadFile={uploadFile}
        newFiles={newFiles}
        setNewFiles={setNewFiles}
        search={search}
        setSearch={setSearch}
        moving={moving}
        setMoving={setMoving}
        acceptMove={acceptMove}
      />
    ) : null}
    {!preview ? (
      <AllFiles
        currentUser={currentUser}
        folders={folders}
        files={files}
        loading={loading}
        onOpenFolder={onOpenFolder}
        onOpenFile={onOpenFile}
        folderRef={folderRef}
        newFolder={newFolder}
        onChangeName={onChangeName}
        deleteFolder={deleteFolder}
        freeEdit={freeEdit}
        deleteFile={deleteFile}
        moving={moving}
        setMoving={setMoving}
      />
    ) : files ? (
      <FilePreview files={files} />
    ) : null}
  </CardContainer>
);

export default FilesView;
