import React from "react";
import { Route } from "react-router-dom";
import { ProfileComponent, AdminComponent } from "components/Profile";
import { useActions } from "utils";

const Profile = props => <ProfileComponent />;

export const ProfileRoute = ({ currentUser, ...props }) => {
  const {
    resetState,
    createUser,
    deleteUser,
    updateUser,
    updateNotifications
  } = useActions(actions => actions.profile);

  const fields = (
    editUser,
    editFunction,
    mailSuccess,
    mailError,
    passwordSuccess,
    passwordError
  ) => [
    {
      widget: "Switch",
      id: "notifications",
      label: " Recibir notificaciones",
      onChange: () => updateNotifications(currentUser),
      adminOnly: true
    },
    {
      label: "Correo",
      icon: "Email",
      placeholder: "usuario@mail.com",
      width: [1, null, null, 0.6, 0.3],
      name: "email",
      value: "mail",
      onChange: e => editFunction("mail", e.target.value),
      error: mailError,
      success: mailSuccess
    },
    {
      widget: "Checkbox",
      id: "changePassword",
      label: "Cambiar contraseña",
      onChange: () => editFunction("changePassword", !editUser.changePassword)
    },
    {
      label: "Contraseña actual",
      icon: "Lock",
      width: [1, null, null, 0.6, 0.3],
      type: "password",
      name: "currentPassword",
      value: "currentPassword",
      onChange: e => editFunction("currentPassword", e.target.value),
      error: passwordError,
      success: passwordSuccess
    },
    {
      label: "Nueva contraseña",
      icon: "Lock",
      width: [1, null, null, 0.6, 0.3],
      type: "password",
      name: "password",
      value: "password",
      onChange: e => editFunction("password", e.target.value),
      others: {
        values: ["password", "confirmPassword"],
        icon: true
      }
    },
    {
      label: "Confirmar contraseña",
      icon: "Lock",
      width: [1, null, null, 0.6, 0.3],
      type: "password",
      name: "confirmPassword",
      value: "confirmPassword",
      onChange: e => editFunction("confirmPassword", e.target.value),
      others: {
        values: ["password", "confirmPassword"],
        icon: true,
        tooltip: true
      }
    }
  ];
  return (
    <Route
      {...props}
      render={props =>
        currentUser.admin ? (
          <AdminComponent
            currentUser={currentUser}
            resetState={resetState}
            createUser={createUser}
            deleteUser={deleteUser}
            updateUser={updateUser}
            fields={fields}
            {...props}
          />
        ) : (
          <ProfileComponent
            currentUser={currentUser}
            resetState={resetState}
            updateUser={updateUser}
            fields={fields}
            {...props}
          />
        )
      }
    />
  );
};

export default Profile;
