import React from "react";
import {
  Flex,
  Box,
  CardContainer,
  Heading,
  LoadingButton,
  Form,
  InputField,
  Button,
  Banner
} from "components/UI";

const Login = ({ fields, onSubmit, onReset, msg }) => (
  <CardContainer
    title={<Heading textAlign="center">Ingresar</Heading>}
    width={[1, 3 / 4, 2 / 3, 1 / 2]}
  >
    {msg ? (
      <Banner px={3} py={2} showIcon={false}>
        {msg}
      </Banner>
    ) : null}
    <Form>
      <Flex justifyContent="space-around">
        {fields.map(({ width, label, ...rest }) => (
          <Box key={label} width={width} p={1}>
            <InputField label={label} {...rest} />
          </Box>
        ))}
        <Box width={1} p={1}>
          <LoadingButton type="primary" width={1} onClick={onSubmit}>
            Ingresar
          </LoadingButton>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end">
        <Button type="link" onClick={onReset}>
          Resetear contraseña
        </Button>
      </Flex>
    </Form>
  </CardContainer>
);

export default Login;
