import React from "react";
import { Flex, Box, Text, Button, MyIcon } from "components/UI";
import Picker from "react-month-picker";
import "react-month-picker/css/month-picker.css";

const months = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "ocutbre",
  "noviembre",
  "diciembre"
];

//({ today, onMonthSelect, calendarDate, pickMonthRef })
const Toolbar = ({
  today,
  onMonthSelect,
  calendarDate,
  pickMonthRef
}) => props => (
  <Flex justifyContent="flex-start" flexWrap="wrap-reverse">
    <Button.Group>
      <Button
        type="normal"
        onClick={() =>
          onMonthSelect(calendarDate.getFullYear(), calendarDate.getMonth())
        }
      >
        <MyIcon type="left" />
      </Button>
      <Button
        type="normal"
        onClick={() =>
          onMonthSelect(calendarDate.getFullYear(), calendarDate.getMonth() + 2)
        }
      >
        <MyIcon type="right" />
      </Button>
    </Button.Group>
    <Box ml={1}>
      <Button
        shape="round"
        type="normal"
        onClick={() =>
          onMonthSelect(today.getFullYear(), today.getMonth(), today.getDate())
        }
      >
        Hoy
      </Button>
    </Box>
    <Box width={[1]}>
      <Flex justifyContent="center">
        <Picker
          ref={pickMonthRef}
          years={{
            min: today.getFullYear() - 10,
            max: today.getFullYear() + 10
          }}
          value={{
            year: calendarDate.getFullYear(),
            month: calendarDate.getMonth() + 1
          }}
          lang={months.map(m => m.slice(0, 3) + ".")}
          onChange={onMonthSelect}
        >
          <Text
            caps
            onMouseEnter={() => {
              document.body.style.cursor = "pointer";
            }}
            onMouseLeave={() => {
              document.body.style.cursor = "default";
            }}
            onClick={e => pickMonthRef.current.show()}
          >
            {months[calendarDate.getMonth()]} {calendarDate.getFullYear()}
          </Text>
        </Picker>
      </Flex>
    </Box>
  </Flex>
);

export default Toolbar;
