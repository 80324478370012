import { firestore, storage } from "config";

const initialState = {
  folders: [],
  files: [],
  route: ["Fotos"],
  ref: "Fotos",
  restricted: [false]
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "RESET":
      return {
        folders: [],
        files: [],
        route: ["Fotos"],
        ref: "Fotos",
        restricted: [false]
      };
    case "ADD_ROUTE":
      return {
        folders: [],
        files: [],
        route: state.route.concat([payload.route]),
        ref: state.ref.concat(`/${payload.key}/directory`),
        restricted: state.restricted.concat([payload.restricted])
      };
    case "REMOVE_ROUTE":
      return {
        folders: [],
        files: [],
        route: state.route.slice(0, payload + 1),
        ref: state.ref
          .split("/")
          .slice(0, payload * 2 + 1)
          .join("/"),
        restricted: state.restricted.slice(0, payload + 1)
      };
    case "SET_FILES":
      return { ...state, files: state.files.concat([payload]) };
    case "SET_FOLDERS":
      return { ...state, folders: state.folders.concat([payload]) };
    case "DELETE_FOLDER":
      return {
        ...state,
        folders: state.folders.filter(folder => folder.key !== payload.key)
      };
    case "DELETE_FILE":
      return {
        ...state,
        files: state.files.filter(file => file.key !== payload.key)
      };
    case "UPDATE":
      return { ...state, ...payload };
    default:
      return state;
  }
};

const moveItem = (item, newPath) => {
  const itemRef = firestore.collection("Fotos").doc(item.key);
  return itemRef.update({ path: newPath });
};

const deleteFile = file => {
  const storageRef = storage.ref(`Fotos/${file.key}.${file.type}`);
  const fileRef = firestore.collection("Fotos").doc(file.key);
  return Promise.all([fileRef.delete(), storageRef.delete()]);
};

export const actions = dispatch => ({
  resetState: () => {
    dispatch({ type: "RESET" });
  },
  addRoute: (route, key, restricted) => {
    dispatch({ type: "ADD_ROUTE", payload: { route, key, restricted } });
  },
  removeRoute: position =>
    position === 0
      ? dispatch({ type: "RESET" })
      : dispatch({ type: "REMOVE_ROUTE", payload: position }),
  updateState: payload => {
    // Actualizar estado
    dispatch({ type: "UPDATE", payload });
  },
  createFolder: async (route, folder) => {
    // Crear carpeta
    const folderObj = {
      name: folder.name,
      restricted: folder.restricted,
      type: "folder",
      date: new Date(),
      path: route.join("/")
    };
    const fileRef = await firestore.collection("Fotos").add(folderObj);
    dispatch({
      type: "SET_FOLDERS",
      payload: { ...folderObj, key: fileRef.id }
    });
  },
  moveFolder: async (currentUser, folder, route) => {
    if (currentUser.admin) {
      const folderPath = route.join("/");
      const currentPath = `${folder.path}/${folder.name}`;
      const currentPathLength = currentPath.split("/").length;
      const endPath =
        currentPath.slice(0, -1) +
        String.fromCharCode(currentPath.slice(-1).charCodeAt(0) + 1);
      const folderRef = firestore.collection("Fotos");
      const subDirs = await folderRef
        .where("path", ">=", currentPath)
        .where("path", "<", endPath)
        .get();
      return Promise.all(
        subDirs.docs
          .map(doc => {
            const { path } = doc.data();
            const listPath = path.split("/");
            const newPath = [folderPath, folder.name]
              .concat(listPath.slice(currentPathLength))
              .join("/");
            return moveItem({ key: doc.id }, newPath);
          })
          .concat([moveItem(folder, folderPath)])
      )
        .then(() => dispatch({ type: "SET_FOLDERS", payload: folder }))
        .catch(err =>
          dispatch({
            type: "MOVE_FOLDER_ERROR",
            payload: err
          })
        );
    }
    return dispatch({
      type: "MOVE_FOLDER_ERROR",
      payload: "Sólo administradores"
    });
  },
  deleteFolder: async (currentUser, folder) => {
    if (currentUser.admin) {
      const path = `${folder.path}/${folder.name}`;
      const endPath =
        path.slice(0, -1) +
        String.fromCharCode(path.slice(-1).charCodeAt(0) + 1);
      console.log("borrar carpeta", folder.key, path, endPath);
      const folderRef = firestore.collection("Fotos");
      const subDirs = await folderRef
        .where("path", ">=", path)
        .where("path", "<", endPath)
        .get();
      return Promise.all(
        subDirs.docs
          .map(doc => {
            const { type } = doc.data();
            return type === "folder"
              ? folderRef.doc(doc.id).delete()
              : deleteFile({ key: doc.id, type });
          })
          .concat([folderRef.doc(folder.key).delete()])
      ).then(() => dispatch({ type: "DELETE_FOLDER", payload: folder }));
    }
    return dispatch({
      type: "DELETE_FOLDER_ERROR",
      payload: "Sólo administradores"
    });
  },
  uploadFile: (route, file, load, currentUser) => {
    const listName = file.name.split(".");
    const name = listName.slice(0, listName.length - 1).join(".");
    const type = listName.pop();
    const fileObj = {
      name: name,
      type: type,
      author: currentUser.uid,
      date: new Date(),
      path: route.join("/")
    };
    firestore
      .collection("Fotos")
      .add(fileObj)
      .then(fileRef => {
        const uploadTask = storage.ref(`Fotos/${fileRef.id}.${type}`).put(file);
        uploadTask.on(
          "state_changed",
          snapshot => {},
          error => {
            console.log(error);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(url => {
              load(fileRef);
              dispatch({
                type: "SET_FILES",
                payload: { ...fileObj, url: url }
              });
            });
          }
        );
      })
      .catch(err => {
        dispatch({ type: "UPLOAD_FILE_ERROR" }, err);
      });
  },
  moveFile: (currentUser, file, newPath) => {
    if (currentUser.admin) {
      return moveItem(file, newPath.join("/"))
        .then(() => dispatch({ type: "SET_FILES", payload: file }))
        .catch(err =>
          dispatch({
            type: "MOVE_FILE_ERROR",
            payload: err
          })
        );
    }
    return dispatch({
      type: "MOVE_FILE_ERROR",
      payload: "Sólo administradores"
    });
  },
  deleteFile: (currentUser, file) => {
    // Borrar archivo
    console.log("borrar archivo", file);
    if (currentUser.admin) {
      return deleteFile(file)
        .then(() => dispatch({ type: "DELETE_FILE", payload: file }))
        .catch(err =>
          dispatch({
            type: "DELETE_FILE_ERROR",
            payload: err
          })
        );
    }
    return dispatch({
      type: "DELETE_FILE_ERROR",
      payload: "Sólo administradores"
    });
  }
});
