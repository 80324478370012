import { useCollection } from "react-firebase-hooks/firestore";
import { firestore } from "config";

export default () => {
  const [values, loading] = useCollection(
    firestore.collection("users").where("admin", "==", true)
  );
  return !values
    ? [values, loading]
    : [
        values.docs.reduce((obj, doc) => {
          obj[doc.id] = {
            uid: doc.id,
            ...doc.data()
          };
          return obj;
        }, {}),
        loading
      ];
};
