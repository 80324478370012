import React from "react";
import {
  Flex,
  Box,
  Card,
  Link,
  Input,
  IconButton,
  Hide,
  Text,
  Divider,
  Breadcrumb,
  Button,
  MyIcon,
  LoadingButton
} from "components/UI";
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginFileRename);

const Toolbar = ({
  currentUser,
  route,
  maxFolders,
  restricted,
  onClickRoute,
  createFolder,
  uploadFile,
  newFiles,
  setNewFiles,
  search,
  setSearch,
  moving,
  setMoving,
  acceptMove
}) => (
  <Flex>
    <Box mb={2} width={1}>
      <Card>
        <Hide xs>
          <Flex display="inline-flex" justifyContent="flex-start">
            <Breadcrumb>
              {route.map((r, pos) => (
                <Breadcrumb.Item key={pos}>
                  <Link onClick={() => onClickRoute(pos)}>
                    {pos === 0 ? <MyIcon type="home" /> : r}
                  </Link>
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </Flex>
        </Hide>
        <Hide sm md lg xl>
          <Flex display="inline-flex" justifyContent="flex-start">
            <Box width={1 / 3}>
              <IconButton
                name="ArrowLeft"
                size={24}
                color="black"
                title={route.length > 1 ? route[route.length - 2] : "Nada"}
                disabled={route.length > 1 ? false : true}
                onClick={() => onClickRoute(route.length - 2)}
              />
            </Box>
            <center>
              <Box width={1 / 3}>
                <Text>{route[route.length - 1]}</Text>
              </Box>
            </center>
            <Box width={1 / 3} />
          </Flex>
        </Hide>
      </Card>
    </Box>
    {!moving ? (
      <SearchBar
        currentUser={currentUser}
        search={search}
        setSearch={setSearch}
        restricted={restricted}
        route={route}
        maxFolders={maxFolders}
        createFolder={createFolder}
        setNewFiles={setNewFiles}
        newFiles={newFiles}
        uploadFile={uploadFile}
      />
    ) : (
      <Move acceptMove={acceptMove} setMoving={setMoving} />
    )}
    <Box width={1} mt={2}>
      <Divider />
    </Box>
  </Flex>
);

const SearchBar = ({
  currentUser,
  search,
  setSearch,
  restricted,
  route,
  maxFolders,
  createFolder,
  setNewFiles,
  newFiles,
  uploadFile
}) => (
  <React.Fragment>
    <Box width={1}>
      <Flex alignItems="center" justifyContent="center">
        <Box width={[1, 18 / 20]}>
          <Input
            id="search"
            placeholder="Buscador"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </Box>
        {currentUser.admin || !restricted ? (
          <React.Fragment>
            <Box mx={1} mt={2}>
              <Button
                type="default"
                shape="circle"
                icon="folder"
                title="Crear carpeta"
                disabled={route.length > maxFolders ? true : false}
                onClick={() => createFolder(route.join("/"), "Carpeta")}
              />
              <Hide sm md lg xl>
                <Text>Carpeta</Text>
              </Hide>
            </Box>
            <Box mx={1} mt={2}>
              <Button
                type="default"
                shape="circle"
                icon="file-add"
                title="Agregar archivo"
                onClick={setNewFiles}
              />
              <Hide sm md lg xl>
                <Text>Archivo</Text>
              </Hide>
            </Box>
          </React.Fragment>
        ) : null}
      </Flex>
    </Box>
    {newFiles !== null ? (
      <Box width={1} mt={2}>
        <FilePond
          files={newFiles}
          allowMultiple={true}
          maxFiles={3}
          server={{
            // fake server to simulate loading a 'local' server file and processing a file
            process: (fieldName, file, metadata, load) => {
              uploadFile(file, load);
            }
          }}
          onprocessfiles={() => setNewFiles(null)}
        />
      </Box>
    ) : null}
  </React.Fragment>
);

const Move = ({ acceptMove, setMoving }) => (
  <React.Fragment>
    <Box width={1}>
      <Text align="center">¿Mover aquí?</Text>
    </Box>
    <Flex width={1} justifyContent="center">
      <Box mx={1}>
        <LoadingButton onClick={acceptMove} icon="check" shape="circle" />
      </Box>
      <Box mx={1}>
        <LoadingButton
          onClick={() => setMoving(null)}
          icon="close"
          type="danger"
          shape="circle"
        />
      </Box>
    </Flex>
  </React.Fragment>
);

export default Toolbar;
