import React from "react";
import { Redirect } from "react-router-dom";
import { useForm, useActions, useLoginState } from "utils";
import LoginView from "./LoginView";

const Login = props => {
  const message = useLoginState("msg");
  console.log(message);
  const { setSignIn, resetPassword } = useActions(actions => actions.login);
  const [user, bind] = useForm({
    email: "",
    password: ""
  });
  const fields = [
    {
      ...bind("email"),
      label: "Email",
      icon: "mail",
      placeholder: "usuario@correo.com",
      width: [1, null, null, 1 / 2]
    },
    {
      ...bind("password"),
      label: "Password",
      type: "password",
      icon: "lock",
      placeholder: "Contraseña",
      width: [1, null, null, 1 / 2]
    }
  ];
  return (
    <LoginView
      fields={fields}
      onSubmit={() => setSignIn(user)}
      onReset={() => {
        if (user.email) {
          return resetPassword(user.email);
        }
      }}
      msg={message}
    />
  );
};

export default Login;
