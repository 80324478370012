import {history} from './Providers';

const initialState = null;

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REDIRECT_TO':
      history.push (action.payload.url);
      return state;
    default:
      return state;
  }
};

export const actions = dispatch => ({
  redirectTo: url => dispatch ({type: 'REDIRECT_TO', payload: {url}}),
});
