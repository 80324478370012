import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonView = () => (
  <React.Fragment>
    <Skeleton height={100} />
    <Skeleton height={500} />
  </React.Fragment>
);

export default SkeletonView;
