import React, { useState } from "react";
import { Switch } from "antd";

export default ({ onChange, checked, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [isChecked, setChecked] = useState(checked);
  const onChangeHandler = async () => {
    setLoading(true);
    setChecked(!isChecked);
    await onChange();
    setLoading(false);
  };
  return (
    <Switch
      onChange={onChangeHandler}
      loading={loading}
      checked={isChecked}
      {...rest}
    />
  );
};
