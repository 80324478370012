import React from "react";
import folderImage from "assets/images/folder.png";
import fileImage from "assets/images/file.png";
import Skeleton from "react-loading-skeleton";
import { Flex, Box } from "components/UI";
import Summary from "./Summary";

const AllFiles = ({
  currentUser,
  folders,
  files,
  loading,
  onOpenFolder,
  onOpenFile,
  folderRef,
  newFolder,
  onChangeName,
  deleteFolder,
  freeEdit,
  deleteFile,
  moving,
  setMoving
}) =>
  !loading && folders && files ? (
    <Flex alignItems="stretch" justifyContent="flex-start">
      {newFolder !== null ? (
        <Box p={1} width={[1, 1 / 2, null, 1 / 3, 1 / 4]}>
          <Summary
            imgSrc={folderImage}
            edit={true}
            folderRef={folderRef}
            newFolder={newFolder}
            onChangeName={onChangeName}
            freeEdit={freeEdit}
          />
        </Box>
      ) : null}
      {folders.map(folder =>
        moving && moving.key === folder.key ? null : (
          <Box key={folder.name} p={1} width={[1, 1 / 2, null, 1 / 3, 1 / 4]}>
            <Summary
              imgSrc={folderImage}
              name={folder.name}
              date={folder.date}
              restricted={folder.restricted}
              onClick={() => onOpenFolder(folder, folder.key)}
              options={currentUser.admin ? true : false}
              deleteItem={() => deleteFolder(folder)}
              moving={moving}
              setMoving={() => setMoving(folder)}
            />
          </Box>
        )
      )}
      {!moving
        ? files.map(file => (
            <Box key={file.name} p={1} width={[1, 1 / 2, null, 1 / 3, 1 / 4]}>
              <Summary
                imgSrc={
                  file.type.toLowerCase() === "jpg" ? file.url : fileImage
                }
                name={file.name}
                author={file.author}
                date={file.date}
                options={currentUser.admin ? true : false}
                onClick={() => onOpenFile(file.url)}
                deleteItem={() => deleteFile(file)}
                moving={moving}
                setMoving={() => setMoving(file)}
              />
            </Box>
          ))
        : null}
    </Flex>
  ) : loading ? (
    <Flex alignItems="stretch">
      <SkeletonFile />
      <SkeletonFile />
      <SkeletonFile />
      <SkeletonFile />
      <SkeletonFile />
      <SkeletonFile />
      <SkeletonFile />
      <SkeletonFile />
    </Flex>
  ) : (
    <Flex>No hay archivos</Flex>
  );

const SkeletonFile = () => (
  <Box p={1} width={[1, 1 / 2, null, 1 / 3, 1 / 4]}>
    <Skeleton width={200} height={200} />
    <Skeleton width={200} count={3} />
  </Box>
);

export default AllFiles;
