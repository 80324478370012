import React from "react";
import { useActions } from "utils";
import { NavBar, MyIcon } from "components/UI";

const AppNavBar = ({ currentUser, current }) => {
  const actions = useActions(actions => actions);
  const navBarProps = {
    current: current,
    mainItem: { to: "/", label: "Inicio", main: true },
    leftItems: !currentUser
      ? []
      : [
          { to: "/nosotros", label: "¿Quiénes somos?" },
          { to: "/estados", label: "Estados Financieros" },
          { to: "/informes", label: "Documentos" },
          { to: "/archivos", label: "Fotos" },
          { to: "/calendario", label: "Reservas" }
        ],
    rightItems: currentUser
      ? [
          { to: "/config", label: <MyIcon type="setting" /> },
          {
            to: "/ingresar",
            label: "Salir",
            onClick: () => {
              actions.login.setSignOut();
            }
          }
        ]
      : [{ to: "/ingresar", label: "Ingresar" }]
  };
  return <NavBar {...navBarProps} />;
};

export default AppNavBar;
