import { useDocumentData } from "react-firebase-hooks/firestore";
import { firestore } from "config";

export default (uid, initialising = false) => {
  const [value, loading] = useDocumentData(
    uid ? firestore.doc(`users/${uid}`) : undefined
  );
  return [
    uid && value && !loading ? { ...value, uid: uid } : null,
    ((!uid || !value) && uid !== value) || loading || initialising
  ];
};
