import { auth } from "config";

const initialState = { signIn: true, msg: "" };

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_SIGN_IN":
      return {
        ...state,
        msg: "",
        signIn: payload.signIn
      };
    case "PASSWORD_RESET":
      return {
        ...state,
        msg: "Mail enviado."
      };
    default:
      return state;
  }
};

const signIn = (credentials, dispatch) => {
  return auth
    .signInWithEmailAndPassword(credentials.email, credentials.password)
    .then(user => dispatch({ type: "SET_SIGN_IN", payload: { signIn: true } }))
    .catch(err => {
      console.log("ERR", err);
      return dispatch({ type: "SET_SIGN_IN", payload: { signIn: false } });
    });
};

const signOut = dispatch => {
  dispatch({ type: "SET_SIGN_IN", payload: { signIn: false } });
  return auth.signOut();
};

const resetPasswordEmail = (mail, dispatch) => {
  console.log("ENVIARRR RESET", mail);
  return auth
    .sendPasswordResetEmail(mail)
    .then(() => dispatch({ type: "PASSWORD_RESET" }))
    .catch(error => dispatch({ type: "PASSWORD_RESET_ERROR" }));
};

export const actions = dispatch => ({
  setSignIn: credentials => signIn(credentials, dispatch),
  setSignOut: () => signOut(dispatch),
  resetPassword: mail => resetPasswordEmail(mail, dispatch)
});
