import React from "react";
import { Flex, Heading } from "components/UI";

const HomeView = ({}) => (
  <Flex justifyContent="center">
    <Heading.h1 color="white">Agrícola Trekán Ltda.</Heading.h1>
  </Flex>
);

export default HomeView;
