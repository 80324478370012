import React from "react";
import Chart from "react-google-charts";
import { Box } from "components/UI";

const UserPoints = ({ admins }) => (
  <Box width={1}>
    <Chart
      chartType="BarChart"
      width="100%"
      height="400px"
      data={[["Usuario", "Noches", "Pendientes"]].concat(
        admins
          ? Object.values(admins).map(admin => [
              `${admin.firstName} ${admin.lastName}`,
              admin.points,
              admin.pending ? admin.pending : 0
            ])
          : []
      )}
      options={{
        chartArea: { width: "70%" },
        colors: ["#b0120a", "blue"],
        hAxis: {
          title: "Noches acumuladas",
          minValue: 0
        },
        isStacked: true
      }}
    />
  </Box>
);

export default UserPoints;
