import React, { useState } from "react";
import FilesView from "components/Files";
import {
  downloadLink,
  useActions,
  useDirectory,
  useKeyPress,
  useFocusInput,
  useFilesState
} from "utils";

const PhotosContainer = ({ currentUser }) => {
  const restricted = useFilesState("restricted");
  const folders = useFilesState("folders");
  const files = useFilesState("files");
  const route = useFilesState("route");
  //const ref = useFilesState("ref");
  const {
    addRoute,
    removeRoute,
    updateState,
    createFolder,
    moveFolder,
    deleteFolder,
    uploadFile,
    moveFile,
    deleteFile
  } = useActions(actions => actions.files);

  // Actualizar las carpetas y archivos en la ruta actual
  const [loading] = useDirectory(route, updateState);

  // Estados para el manejo de crear carpeta, subir archivos o el buscador
  const [newFolder, setNewFolder] = useState(null);
  const [newFiles, setNewFiles] = useState(null);
  const [search, setSearch] = useState(null);

  // Hook para poner el foco en el input que se le da el ref
  const [folderRef, toggleFocus] = useFocusInput();

  // Hook para mover un archivo o carpeta
  const [moving, setMoving] = useState(null);

  // Hooks para manejar apretar boton del teclado
  const enter = useKeyPress("Enter");
  const esc = useKeyPress("Escape");

  // Manejo de apretar teclas
  if (
    enter &&
    newFolder &&
    !folders.some(
      folder => folder.name.toLowerCase() === newFolder.name.toLowerCase()
    )
  ) {
    createFolder(route, newFolder);
    toggleFocus(false);
    setNewFolder(null);
  } else if (esc && newFolder !== null) {
    toggleFocus(false);
    setNewFolder(null);
  }

  // Funciones de cambiar la ruta
  const onClickRoute = pos => {
    toggleFocus(false);
    setNewFolder(null);
    removeRoute(pos);
  };
  const onOpenFolder = (folder, key) => {
    toggleFocus(false);
    setNewFolder(null);
    addRoute(folder.name, key, folder.restricted);
  };

  // Funcion de mover un item
  const moveItem = () => {
    if (moving.path === route.join("/")) {
      setMoving(null);
      return;
    }
    if (moving.type === "folder") {
      return moveFolder(currentUser, moving, route).then(() => setMoving(null));
    } else {
      return moveFile(currentUser, moving, route).then(() => setMoving(null));
    }
  };

  // Lista de items ordenadas por nombre
  const sortedFolders = folders.sort((a, b) =>
    a.name > b.name ? 1 : a.name < b.name ? -1 : 0
  );
  const sortedFiles = files.sort((a, b) =>
    a.name > b.name ? 1 : a.name < b.name ? -1 : 0
  );

  // Para ver cuantos niveles se puede bajar en carpetas dentro de carpetas
  const maxFolders = 5;
  return (
    <FilesView
      header={"Fotos"}
      currentUser={currentUser}
      route={route}
      maxFolders={maxFolders}
      restricted={restricted}
      loading={loading}
      folders={
        search
          ? sortedFolders.filter(folder =>
              folder.name.toLowerCase().includes(search.toLowerCase())
            )
          : sortedFolders
      }
      files={
        search
          ? sortedFiles.filter(file =>
              file.name.toLowerCase().includes(search.toLowerCase())
            )
          : sortedFiles
      }
      onClickRoute={onClickRoute}
      onOpenFolder={onOpenFolder}
      onOpenFile={downloadLink}
      createFolder={() => {
        if (
          (currentUser.admin || !restricted) &&
          newFolder === null &&
          route.length <= maxFolders
        ) {
          setNewFolder({
            name: "",
            restricted: false
          });
          toggleFocus();
        } else {
          toggleFocus(false);
          setNewFolder(null);
        }
      }}
      deleteFolder={folder => deleteFolder(currentUser, folder)}
      uploadFile={(file, load) => uploadFile(route, file, load, currentUser)}
      deleteFile={file => deleteFile(currentUser, file)}
      preview={false}
      newFolder={newFolder}
      folderRef={folderRef}
      onChangeName={setNewFolder}
      newFiles={newFiles}
      setNewFiles={() =>
        (currentUser.admin || !restricted) && newFiles === null
          ? setNewFiles([])
          : setNewFiles(null)
      }
      search={search}
      setSearch={setSearch}
      freeEdit={true} //Todos pueden editar a menos que se haya especificado especialmente en la carpeta que no se puede
      moving={moving} // Para mover archivos
      setMoving={setMoving}
      acceptMove={moveItem}
    />
  );
};

export default PhotosContainer;
