import React from "react";
import {
  Flex,
  Box,
  Label,
  Checkbox,
  FormField,
  Icon,
  Input,
  Tooltip,
  Banner,
  LoadingSwitch
} from "components/UI";

const EditUser = ({ fields, editUser }) => (
  <Flex justifyContent="center" flexDirection="column">
    {fields.map(
      ({
        widget,
        label,
        icon,
        width,
        name,
        value,
        others,
        type,
        error,
        success,
        adminOnly,
        ...rest
      }) =>
        type !== "password" ||
        (type === "password" && editUser.changePassword) ? (
          <Box key={label} width={width} my={2} alignSelf={"center"}>
            {success ? (
              <Banner mx={1} my={2} showIcon={false} bg="green">
                {success}
              </Banner>
            ) : error ? (
              <Banner mx={1} my={2} showIcon={false} bg="red">
                {error}
              </Banner>
            ) : null}
            {widget === "Checkbox" ? (
              <Label fontSize={1}>
                <Checkbox id={rest.id} {...rest} />
                {label}
              </Label>
            ) : widget === "Switch" ? (
              adminOnly ? (
                <Label fontSize={1}>
                  <LoadingSwitch checked={editUser.notifications} {...rest} />
                  {label}
                </Label>
              ) : null
            ) : (
              <FormField>
                <Label>{label}</Label>
                <Icon name={icon} size="20" />
                <Input
                  id={name}
                  name={name}
                  label={label}
                  icon={icon}
                  value={value ? editUser[value] : value}
                  type={type}
                  {...rest}
                />
                {others &&
                others.icon &&
                editUser[others.values[0]] !== editUser[others.values[1]] ? (
                  <Icon name="Warning" color="red" />
                ) : null}
              </FormField>
            )}
            {others &&
            others.tooltip &&
            editUser[others.values[0]] !== editUser[others.values[1]] ? (
              <Tooltip bottom right color="white" bg="red">
                Contraseñas deben coincidir
              </Tooltip>
            ) : null}
          </Box>
        ) : null
    )}
  </Flex>
);

export default EditUser;
