import React from "react";
import { useUser } from "utils";
import { Box, Flex, Hide, Text, List, Heading, Button } from "components/UI";

import Toolbar from "./Toolbar";
import styles from "./EventsCalendar.module.css";
// Para el calendario grande
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
require("moment/locale/es");

const localizer = momentLocalizer(moment);

const formatDate = date => {
  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ];
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${day} de ${monthNames[month]} de ${year}`;
};

const Event = ({ event, hide = true }) => {
  const [user, loading] = useUser(event.user);
  return (
    <Flex
      flexDirection="column"
      key={event.user}
      bg={event.status ? "red" : ""}
    >
      {hide ? (
        <Hide xs>
          <Text caps>
            {!loading ? `${user.firstName} ${user.lastName}` : "..."}
          </Text>
        </Hide>
      ) : !loading ? (
        <React.Fragment>
          <Text caps>{`${user.firstName} ${user.lastName}`}</Text>
          <Text>Límite:{formatDate(event.deadline)}</Text>
        </React.Fragment>
      ) : (
        "..."
      )}
    </Flex>
  );
};

const DateCell = ({ calendarDate, today }) => ({ range, value, children }) => {
  //console.log(123, children);
  return (
    <Box
      className={
        today.getDate() === value.getDate() &&
        today.getMonth() === value.getMonth() &&
        today.getFullYear() === value.getFullYear()
          ? styles.today
          : value.getMonth() === calendarDate.getMonth()
          ? styles.normal
          : styles.otherMonth
      }
    />
  );
};

const DateHeader = ({ today, calendarDate, setDaySummary, daySummary }) => ({
  label,
  date
}) => (
  <Flex
    justifyContent="center"
    m={1}
    bg={
      today.getDate() === date.getDate() &&
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear()
        ? "rgba(97, 212, 247, 0.25)"
        : date.getMonth() === calendarDate.getMonth()
        ? "rgb(255, 255, 255)"
        : "#ccc"
    }
  >
    <Hide sm md lg xl>
      <Button
        ghost
        size="small"
        type={
          daySummary &&
          daySummary.getDate() === date.getDate() &&
          daySummary.getMonth() === date.getMonth() &&
          daySummary.getFullYear() === date.getFullYear()
            ? "danger"
            : "primary"
        }
        onClick={() => {
          console.log(date);
          setDaySummary(date);
        }}
      >
        <span>{label}</span>
      </Button>
    </Hide>
    <Hide xs>
      <span>{label}</span>
    </Hide>
  </Flex>
);

const EventsCalendar = ({
  calendarDate,
  events,
  today,
  onMonthSelect,
  pickMonthRef,
  daySummary,
  setDaySummary
}) => (
  <React.Fragment>
    <Box width={1} minHeight={[300, 500, 600]}>
      <Calendar
        date={calendarDate}
        events={events}
        components={{
          event: Event,
          dateCellWrapper: DateCell({ calendarDate, today }),
          toolbar: Toolbar({
            today,
            onMonthSelect,
            calendarDate,
            pickMonthRef
          }),
          dateHeader: DateHeader({
            today,
            calendarDate,
            setDaySummary,
            daySummary
          })
        }}
        endAccessor={({ end }) => new Date(end.getTime() + 1)}
        culture="es"
        localizer={localizer}
        popup={false}
        views={["month"]}
        onShowMore={(events, date) => setDaySummary(date)}
        messages={{
          showMore: target => <Text>más</Text>
        }}
      />
    </Box>
    <Hide sm md lg xl>
      {daySummary ? <DaySummary events={events} date={daySummary} /> : null}
    </Hide>
  </React.Fragment>
);

const DaySummary = ({ events, date }) => (
  <Box mt={5} width={1}>
    <List
      header={<Heading>{formatDate(date)}</Heading>}
      bordered
      dataSource={events.filter(
        event =>
          (event.start <= date && event.end > date) ||
          (event.start < date && event.end >= date)
      )}
      renderItem={item => (
        <List.Item>
          <Event event={item} hide={false} />
        </List.Item>
      )}
    />
  </Box>
);

export default EventsCalendar;
