import styled from "styled-components";
import { Card } from "antd";
import { space, color, maxWidth } from "styled-system";

const MyCard = styled(Card)(color, space, maxWidth);

MyCard.defaultProps = {
  bg: "white"
};

export default MyCard;
