import React from "react";
import {
  Card,
  Box,
  Flex,
  FormField,
  Icon,
  Input,
  Label,
  Checkbox,
  Text,
  CloseButton,
  LoadingButton
} from "components/UI";

const CreateUser = ({ form, setShowing, onChange, createUser }) => (
  <Flex flexDirection="column">
    <Box width={[1, 0.9]} alignSelf={"center"}>
      <Card m={2}>
        <Flex justifyContent="flex-end" color="black">
          <Box mx="auto" />
          <CloseButton onClick={() => setShowing("allUsers")} />
        </Flex>
        <Flex justifyContent="center">
          <Text>Nuevo Usuario</Text>
        </Flex>
        <Flex justifyContent="center">
          <Box m={2} width={[1, 0.45, 0.4]} alignSelf={"center"}>
            <FormField>
              <Label>Nombre</Label>
              <Icon name="SingleOccupancy" size="20" />
              <Input
                name="name"
                label="Nombre"
                icon="SingleOccupancy"
                value={form.firstName}
                type="text"
                onChange={e => onChange("firstName", e.target.value)}
                placeholder="Juan"
              />
            </FormField>
          </Box>
          <Box m={2} width={[1, 0.45, 0.4]} alignSelf={"center"}>
            <FormField>
              <Label>Apellido</Label>
              <Icon name="SingleOccupancy" size="20" />
              <Input
                name="mail"
                label="Correo"
                icon="SingleOccupancy"
                value={form.lastName}
                type="text"
                onChange={e => onChange("lastName", e.target.value)}
                placeholder="Perez"
              />
            </FormField>
          </Box>
        </Flex>
        <Flex justifyContent="center">
          <Box m={2} width={[1, 0.9, 0.82]} alignSelf={"center"}>
            <FormField>
              <Label>Correo</Label>
              <Icon name="Email" size="20" />
              <Input
                name="mail"
                label="Correo"
                icon="Email"
                value={form.mail}
                type="email"
                onChange={e => onChange("mail", e.target.value)}
                placeholder="usuario@correo.cl"
              />
            </FormField>
          </Box>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Box m={2} width={[1, 0.9, 0.82]} alignSelf={"center"}>
            <Label>
              <Checkbox
                id="admin"
                onChange={e => onChange("admin", !form.admin)}
              />
              Admin
            </Label>
          </Box>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <LoadingButton onClick={createUser} shape="round">
            Crear
          </LoadingButton>
        </Flex>
      </Card>
    </Box>
  </Flex>
);

export default CreateUser;
