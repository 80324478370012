import React from "react";
import { Container, GradientBackgroundImage, Flex, Box } from "components/UI";
import background from "assets/images/background.jpg";

//"https://thiswallpaper.com/cdn/hdwallpapers/943/orange%20tree%20awesome%20background%20image.jpg"
const AppContainer = ({ children }) => (
  <GradientBackgroundImage
    image={background}
    height={550}
    gradientHeight="80%"
    from="rgba(0, 0, 0, 0)"
    to="#000000"
  >
    <Container maxWidth={1280}>
      <Flex p={[2, 3]}>
        <Box width={1}>{children}</Box>
      </Flex>
    </Container>
  </GradientBackgroundImage>
);

export default AppContainer;
