import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useCurrentUser } from "utils";
import { AppContainer, AppNavBar } from "components/App";
import Home from "./home";
import Statements from "./statements";
import Reports from "./reports";
import Photos from "./photos";
import Skeleton from "./skeleton";
import Login from "./login";
import { ProfileRoute } from "./profile";
import Calendar from "./calendar";
import About from "./about";
import NotFound from "components/NotFound";

const Root = props => {
  const [user, loading] = useCurrentUser();
  return !loading ? (
    <React.Fragment>
      <AppNavBar currentUser={user} current={props.location.pathname} />
      <AppContainer>
        <Switch>
          <Route path="/" exact render={() => <Home />} />
          <Route
            path="/ingresar"
            render={props => <Login {...props} currentUser={user} />}
          />
          {user ? (
            <React.Fragment>
              <Route path="/nosotros" component={About} />
              <Route
                path="/estados"
                render={props => <Statements {...props} currentUser={user} />}
              />
              <Route
                path="/informes"
                render={props => <Reports {...props} currentUser={user} />}
              />
              <Route
                path="/archivos"
                render={props => <Photos {...props} currentUser={user} />}
              />
              <Route
                path="/calendario"
                render={props => <Calendar {...props} currentUser={user} />}
              />
              <ProfileRoute path="/config" currentUser={user} />
            </React.Fragment>
          ) : (
            <Route path="" render={props => <Redirect to="/ingresar" />} />
          )}
          <Route path="" component={NotFound} />
        </Switch>
      </AppContainer>
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default Root;
