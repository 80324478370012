import { emailAuthProvider, auth, firestore, functions } from "config";

const initialState = {
  mailSuccess: "",
  mailError: "",
  passwordSuccess: "",
  passwordError: ""
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "EMAIL_RESET":
      return { ...state, mailSuccess: payload };
    case "EMAIL_RESET_ERROR":
      return { ...state, mailError: payload };
    case "PASSWORD_RESET":
      return { ...state, passwordSuccess: payload };
    case "PASSWORD_RESET_ERROR":
      return { ...state, passwordError: payload };
    case "RESET_STATE":
      return { mailError: "", passwordError: "" };
    default:
      return state;
  }
};

export const actions = dispatch => ({
  resetState: () => dispatch({ type: "RESET_STATE" }),
  createUser: (newUser, currentUser) => {
    if (!currentUser.admin) {
      return dispatch({ type: "UNAUTHORIZED" });
    }
    console.log(newUser, currentUser);
    const createUser = functions.httpsCallable("createUser");
    return createUser({ newUser: { ...newUser, points: 0 }, currentUser })
      .then(() => {
        dispatch({ type: "CREATE_USER" });
        return true;
      })
      .catch(err => {
        dispatch({ type: "SIGNUP_ERROR", payload: err });
        return false;
      });
  },
  deleteUser: (user, currentUser) => {
    if (!currentUser.admin) {
      return dispatch({ type: "UNAUTHORIZED" });
    }
    const deleteUser = functions.httpsCallable("deleteUser");
    return deleteUser({ user, currentUser })
      .then(() => {
        dispatch({ type: "DELETE_USER" });
        return true;
      })
      .catch(err => {
        dispatch({ type: "DELETE_EROR" });
        return false;
      });
  },
  updateUser: async ({
    currentUser,
    mail,
    currentPassword,
    password,
    confirmPassword
  }) => {
    //const currentUser = auth.currentUser;
    if (password && password === confirmPassword) {
      var credential = await emailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      );
      await auth.currentUser
        .reauthenticateAndRetrieveDataWithCredential(credential)
        .then(() => {
          auth.currentUser
            .updatePassword(password)
            .then(() => {
              dispatch({
                type: "PASSWORD_RESET",
                payload: "Contraseña cambiada"
              });
              return true;
            })
            .catch(err => {
              console.log(err);
              dispatch({
                type: "PASSWORD_RESET_ERROR",
                payload: err.message
              });
              return false;
            });
        })
        .catch(err => {
          dispatch({
            type: "PASSWORD_RESET_ERROR",
            payload: "Clave Incorrecta"
          });
          return false;
        });
    }
    if (mail && mail !== currentUser.mail) {
      console.log("actualizar correo");
      await currentUser
        .updateEmail(mail)
        .then(() => {
          firestore
            .collection("users")
            .doc(currentUser.uid)
            .update({ mail })
            .then(() => {
              dispatch({ type: "EMAIL_RESET", payload: "Mail cambiada" });
            })
            .catch(err => {
              dispatch({ type: "EMAIL_RESET_ERROR", payload: err.message });
            });
        })
        .catch(err => {
          dispatch({ type: "EMAIL_RESET_ERROR", payload: err.message });
        });
    }
  },
  updateNotifications: currentUser =>
    firestore
      .collection("users")
      .doc(currentUser.uid)
      .update({ notifications: !currentUser.notifications })
      .then(
        dispatch({ type: "NOTIFICATIONS", payload: !currentUser.notifications })
      )
});
