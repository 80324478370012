import { useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { getFirestoreCol } from "utils";

export default updateEvents => {
  const collection = getFirestoreCol("campoRequests");
  const [value, loading, error] = useCollection(collection);
  useEffect(() => {
    let isSubscribed = true;
    !loading && isSubscribed && updateEvents(value.docs);
    return () => (isSubscribed = false);
  }, [value, loading, updateEvents]);
  return [loading, error];
};
