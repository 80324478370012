import React from "react";
import { useUser } from "utils";
import {
  Card,
  Hide,
  Flex,
  Box,
  Text,
  IconButton,
  Hug,
  LoadingButton,
  Affix
} from "components/UI";

const AllUsers = ({
  currentUser,
  users,
  showing,
  setShowing,
  showUser,
  setShowUser,
  deleteUser
}) => (
  <Card mt={2}>
    <Hide xs sm md>
      <Flex justifyContent="center" flexDirection="column">
        <Flex key={"title"}>
          <Box width={0.35}>
            <Affix offsetTop={10}>
              <Text fontSize={2} align="center" bold>
                Nombre
              </Text>
            </Affix>
          </Box>
          <Box width={[0.2, 0.3]}>
            <Affix offsetTop={10}>
              <Text fontSize={2} align="center" bold>
                Correo
              </Text>
            </Affix>
          </Box>
          <Box width={[0.4, 0.25]}>
            <Affix offsetTop={10}>
              <Text fontSize={2} align="center" bold>
                Creador
              </Text>
            </Affix>
          </Box>
          <Flex
            width={0.1}
            justifyContent="flex-end"
            alignItems="flex-start"
            mt={-2}
          >
            <IconButton
              name="BoxPlus"
              size={24}
              color="black"
              title="Agregar usuario"
              onClick={() =>
                showing !== "newUser" ? setShowing("newUser") : setShowing(null)
              }
            />
          </Flex>
        </Flex>
        {Object.values(users)
          .sort((a, b) =>
            a.firstName > b.firstName ? 1 : a.firstName < b.firstName ? -1 : 0
          )
          .map(user => (
            <Flex key={user.uid} my={2}>
              <Box width={0.35}>
                <Text>
                  {user.firstName} {user.lastName}
                </Text>
              </Box>
              <Box width={[0.2, 0.3]}>
                <Text>{user.mail}</Text>
              </Box>
              <Box width={[0.4, 0.25]}>
                {user.admin ? (
                  <Text caps>Admin</Text>
                ) : (
                  <Creator uid={user.creator} />
                )}
              </Box>
              <Flex width={0.05} justifyContent="flex-end">
                <LoadingButton
                  onClick={() => {}}
                  shape="circle"
                  icon="edit"
                  title="Editar usuario"
                  disabled
                />
              </Flex>
              <Flex width={0.05} justifyContent="flex-start">
                <LoadingButton
                  onClick={() => deleteUser(user, currentUser)}
                  shape="circle"
                  icon="close"
                  title="Borrar usuario"
                  type="danger"
                />
              </Flex>
            </Flex>
          ))}
      </Flex>
    </Hide>
    <Hide lg xl>
      {Object.values(users)
        .sort((a, b) =>
          a.firstName > b.firstName ? 1 : a.firstName < b.firstName ? -1 : 0
        )
        .map(user => (
          <Box key={user.uid} width={1} mb={1}>
            <Hug
              bg="blue"
              icon={user.uid === showUser.show ? "ChevronDown" : "ChevronRight"}
              text={
                <Text.span onClick={() => setShowUser(user.uid, "show")}>
                  {user.firstName} {user.lastName}
                </Text.span>
              }
            >
              {user.uid === showUser.show ? (
                <UserDetail
                  user={user}
                  currentUser={currentUser}
                  deleteUser={deleteUser}
                />
              ) : null}
            </Hug>
          </Box>
        ))}
      <Flex justifyContent="center">
        <IconButton
          name="BoxPlus"
          size={24}
          color="black"
          title="Agregar usuario"
          onClick={() =>
            showing !== "newUser" ? setShowing("newUser") : setShowing(null)
          }
        />
      </Flex>
    </Hide>
  </Card>
);

const UserDetail = ({ user, currentUser, deleteUser }) => (
  <Card>
    <Flex>
      <Box width={1}>
        <Text bold>Correo</Text>
        {user.mail}
      </Box>
    </Flex>
    <Flex mt={1} justifyContent="center">
      <Box width={0.5}>
        <Text bold>Prioridad</Text>
      </Box>
      <Box width={0.5}>
        <Text bold>Admin</Text>
      </Box>
      <Box width={0.5}>
        <Text>{user.priority}</Text>
      </Box>
      <Box width={0.5}>
        <Text>{user.admin ? "Si" : "No"}</Text>
      </Box>
    </Flex>
    <Flex mt={1} justifyContent="center">
      <Box my={[1, 0]}>
        <LoadingButton
          onClick={() => {}}
          icon="edit"
          title="Editar usuario"
          disabled
        >
          Editar
        </LoadingButton>
      </Box>
      <Box my={[1, 0]}>
        <LoadingButton
          onClick={() => deleteUser(user, currentUser)}
          icon="close"
          title="Borrar usuario"
          type="danger"
        >
          Eliminar
        </LoadingButton>
      </Box>
    </Flex>
  </Card>
);

const Creator = ({ uid }) => {
  const [user] = useUser(uid);
  return user ? (
    <Text>
      {user.firstName} {user.lastName}
    </Text>
  ) : (
    <Text>...</Text>
  );
};

export default AllUsers;
