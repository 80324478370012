import { useState, useEffect, useRef } from "react";

const useFocusInput = () => {
  const [isEditing, setEditing] = useState(false);
  const inputRef = useRef();
  const toggleEditing = state => {
    setEditing(state ? state : !isEditing);
  };

  useEffect(() => {
    if (isEditing) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isEditing]);

  return [inputRef, toggleEditing];
};

export default useFocusInput;
