import React from "react";
import GoogleEarth from "assets/images/GoogleEarth.jpg";
import Abuelos from "assets/images/abuelos.JPG";
import { CardContainer, Heading, Flex, Text, Image } from "components/UI";

const About = () => (
  <CardContainer
    title={<Heading textAlign="center">¿Quiénes somos?</Heading>}
    width={1}
  >
    <Text.p>
      Agrícola Trekán Ltda. es una sociedad creada el 8 de enero del 2007 por
      iniciativa de María Elena Ovalle y Arturo Vigneaux Munita,
      <Flex justifyContent="center" m={3}>
        <Image alt="Foto Google Earth" src={Abuelos} width="300" />
      </Flex>{" "}
      con el propósito de explotar el predio agrícola ubicado a 3.5 km de
      Pomaire, camino El Tránsito, comuna de Melipilla, adquirido en 1998. En
      esta sociedad participan en partes iguales María Elena, Pablo y Juan
      Andrés Vigneaux Ovalle.
    </Text.p>
    <Flex justifyContent="center" m={3}>
      <Image alt="Foto Google Earth" src={GoogleEarth} />
    </Flex>
    <Text.p>
      El predio tiene un huerto de 30 has de naranjas de las variedades
      Fukumoto, Parent Washington y Lane Late, plantado el año 2008, actualmente
      en producción.
    </Text.p>
  </CardContainer>
);

export default About;
