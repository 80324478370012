import React from "react";
import moment from "moment";
import { useUser } from "utils";
import {
  Flex,
  Flag,
  Image,
  Text,
  Box,
  Input,
  Label,
  Checkbox,
  Dropdown,
  Menu,
  MyIcon,
  Button,
  LoadingButton
} from "components/UI";

const FileMenu = ({ onEdit, onDelete, setMoving }) => (
  <Menu>
    <Menu.Item key="1" disabled>
      <LoadingButton type="link" disabled onClick={onEdit}>
        <MyIcon type="edit" />
        Editar
      </LoadingButton>
    </Menu.Item>
    <Menu.Item key="2" onClick={setMoving}>
      <Text align="center">Mover</Text>
    </Menu.Item>
    <Menu.Item key="3">
      <LoadingButton type="link" onClick={onDelete}>
        <MyIcon type="delete" />
        Eliminar
      </LoadingButton>
    </Menu.Item>
  </Menu>
);

const Summary = ({ imgSrc, onClick, ...details }) => (
  <Box height="100%" maxWidth="500px" mr={4} ml={4}>
    <SummaryImage onClick={onClick} imgSrc={imgSrc} />
    {details.edit ? (
      <Editing
        newFolder={details.newFolder}
        onChangeName={details.onChangeName}
        folderRef={details.folderRef}
        freeEdit={details.freeEdit}
      />
    ) : (
      <Info
        onClick={onClick}
        restricted={details.restricted}
        name={details.name}
        options={details.options}
        deleteItem={details.deleteItem}
        date={details.date}
        moving={details.moving}
        setMoving={details.setMoving}
      />
    )}
    {details.author ? <Author uid={details.author} /> : null}
  </Box>
);

const SummaryImage = ({ onClick, imgSrc }) => (
  <Box width={1} height={"65%"} onClick={onClick}>
    <center>
      <Image maxWidth={"75%"} src={imgSrc} />
    </center>
  </Box>
);

const Editing = ({ newFolder, onChangeName, folderRef, freeEdit }) => (
  <React.Fragment>
    <Input
      mt={1}
      id="name"
      value={newFolder.name}
      onChange={e => onChangeName({ ...newFolder, name: e.target.value })}
      ref={folderRef}
    />
    {freeEdit ? (
      <Label>
        <Checkbox
          id="restricted"
          value={newFolder.restricted}
          onClick={e => {
            onChangeName({
              ...newFolder,
              restricted: e.target.checked
            });
          }}
        />{" "}
        Restringido
      </Label>
    ) : null}
  </React.Fragment>
);

const Info = ({
  onClick,
  restricted,
  name,
  options,
  deleteItem,
  date,
  moving,
  setMoving
}) => (
  <React.Fragment>
    <Flex justifyContent="space-between">
      <Flag onClick={onClick} bg={restricted ? "red" : "green"}>
        {name}
      </Flag>
      {options && !moving ? (
        <Dropdown
          placement="bottomRight"
          overlay={
            <FileMenu
              onEdit={() => console.log("editar")}
              onDelete={deleteItem}
              setMoving={setMoving}
            />
          }
          trigger={["click"]}
        >
          <Button type="link" size="large">
            <MyIcon type="more" />
          </Button>
        </Dropdown>
      ) : null}
    </Flex>
    <Text mt={3}>Fecha: {moment(date).fromNow()}</Text>
  </React.Fragment>
);

const Author = ({ uid }) => {
  const [author, loading] = useUser(uid);
  return loading ? (
    <Text>Autor: ...</Text>
  ) : (
    <Text>
      Autor: {author.firstName} {author.lastName}
    </Text>
  );
};

export default Summary;
