import { useSelector } from "react-redux";
/*
export default () => {
  const { calendarDate, events, error } = useSelector(state => state.calendar);
  return [
    new Date(calendarDate),
    events.map(event => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end),
      deadline: new Date(event.deadline)
    })),
    error
  ];
};*/

export default key =>
  useSelector(state => {
    const keyState = state.calendar[key];
    return key === "calendarDate"
      ? new Date(keyState)
      : key === "events"
      ? keyState.map(event => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
          deadline: new Date(event.deadline)
        }))
      : keyState;
  });
