import firebase from "firebase";

// Firebase config
const config = {
  apiKey: "AIzaSyCV4xEMjrO4ULjlySjRbQLgmLFPjnT3QCA",
  authDomain: "campo-e1ba3.firebaseapp.com",
  databaseURL: "https://campo-e1ba3.firebaseio.com",
  projectId: "campo-e1ba3",
  storageBucket: "campo-e1ba3.appspot.com",
  messagingSenderId: "393013829542",
  appId: "1:393013829542:web:b1fe1d912100ec09"
};

firebase.initializeApp(config);

export default firebase;

export const database = firebase.database();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
