import React from "react";
import Viewer from "react-viewer";
import { Flex, Box, CloseButton } from "components/UI";

const FilePreview = ({ files }) => {
  console.log(files);
  return (
    <Flex justifyContent="center">
      <Box width={1}>
        <CloseButton />
      </Box>
      <Box>
        <Viewer
          visible={true}
          onClose={() => {}}
          images={[
            {
              src:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Natalie_Portman_Cannes_2015_5.jpg/220px-Natalie_Portman_Cannes_2015_5.jpg",
              alt: "Natalie Portman"
            }
          ]}
        />
      </Box>
    </Flex>
  );
};

export default FilePreview;
