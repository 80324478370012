import { firestore, storage } from "config";

const initialState = {
  foldersReports: [],
  filesReports: [],
  routeReports: ["Informes"],
  refReports: "Informes"
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "RESET_REPORTS":
      return {
        foldersReports: [],
        filesReports: [],
        routeReports: ["Informes"],
        refReports: "Informes"
      };
    case "ADD_ROUTE_REPORTS":
      return {
        foldersReports: [],
        filesReports: [],
        routeReports: state.routeReports.concat([payload.route]),
        refReports: state.refReports.concat(`/${payload.key}/directory`)
      };
    case "REMOVE_ROUTE_REPORTS":
      return {
        foldersReports: [],
        filesReports: [],
        routeReports: state.routeReports.slice(0, payload + 1),
        refReports: state.refReports
          .split("/")
          .slice(0, payload * 2 + 1)
          .join("/")
      };
    case "SET_FILES_REPORTS":
      return { ...state, filesReports: state.filesReports.concat([payload]) };
    case "SET_FOLDERS_REPORTS":
      return {
        ...state,
        foldersReports: state.foldersReports.concat([payload])
      };
    case "DELETE_FOLDER_REPORTS":
      return {
        ...state,
        foldersReports: state.foldersReports.filter(
          folder => folder.name.toLowerCase() !== payload.name.toLowerCase()
        )
      };
    case "DELETE_FILE_REPORTS":
      return {
        ...state,
        files: state.files.filter(file => file.key !== payload.key)
      };
    case "UPDATE_REPORTS":
      return {
        ...state,
        foldersReports: payload.folders,
        filesReports: payload.files
      };
    default:
      return state;
  }
};

const moveItem = (item, newPath) => {
  const itemRef = firestore.collection("Informes").doc(item.key);
  return itemRef.update({ path: newPath });
};

const deleteFile = file => {
  const storageRef = storage.ref(`Informes/${file.key}.${file.type}`);
  const fileRef = firestore.collection("Informes").doc(file.key);
  return Promise.all([fileRef.delete(), storageRef.delete()]);
};

export const actions = dispatch => ({
  resetState: () => {
    dispatch({ type: "RESET_REPORTS" });
  },
  addRoute: (route, key) => {
    dispatch({ type: "ADD_ROUTE_REPORTS", payload: { route, key } });
  },
  removeRoute: position =>
    position === 0
      ? dispatch({ type: "RESET_REPORTS" })
      : dispatch({ type: "REMOVE_ROUTE_REPORTS", payload: position }),
  updateState: payload => {
    // Actualizar estado
    dispatch({ type: "UPDATE_REPORTS", payload });
  },
  createFolder: async (route, folder) => {
    // Crear carpeta
    const folderObj = {
      name: folder.name,
      type: "folder",
      date: new Date(),
      path: route.join("/")
    };
    const fileRef = await firestore.collection("Informes").add(folderObj);
    dispatch({
      type: "SET_FOLDERS_REPORTS",
      payload: { ...folderObj, key: fileRef.id }
    });
  },
  moveFolder: async (currentUser, folder, route) => {
    if (currentUser.admin) {
      const folderPath = route.join("/");
      const currentPath = `${folder.path}/${folder.name}`;
      const currentPathLength = currentPath.split("/").length;
      const endPath =
        currentPath.slice(0, -1) +
        String.fromCharCode(currentPath.slice(-1).charCodeAt(0) + 1);
      const folderRef = firestore.collection("Informes");
      const subDirs = await folderRef
        .where("path", ">=", currentPath)
        .where("path", "<", endPath)
        .get();
      return Promise.all(
        subDirs.docs
          .map(doc => {
            const { path } = doc.data();
            const listPath = path.split("/");
            const newPath = [folderPath, folder.name]
              .concat(listPath.slice(currentPathLength))
              .join("/");
            return moveItem({ key: doc.id }, newPath);
          })
          .concat([moveItem(folder, folderPath)])
      )
        .then(() => dispatch({ type: "SET_FOLDERS_REPORTS", payload: folder }))
        .catch(err =>
          dispatch({
            type: "MOVE_FOLDER_ERROR_REPORTS",
            payload: err
          })
        );
    }
    return dispatch({
      type: "MOVE_FOLDER_ERROR_REPORTS",
      payload: "Sólo administradores"
    });
  },
  deleteFolder: async (currentUser, folder) => {
    if (currentUser.admin) {
      const path = `${folder.path}/${folder.name}`;
      const endPath =
        path.slice(0, -1) +
        String.fromCharCode(path.slice(-1).charCodeAt(0) + 1);
      console.log("borrar carpeta", folder.key, path, endPath);
      const folderRef = firestore.collection("Informes");
      const subDirs = await folderRef
        .where("path", ">=", path)
        .where("path", "<", endPath)
        .get();
      return Promise.all(
        subDirs.docs
          .map(doc => {
            const { type } = doc.data();
            return type === "folder"
              ? folderRef.doc(doc.id).delete()
              : deleteFile({ key: doc.id, type });
          })
          .concat([folderRef.doc(folder.key).delete()])
      ).then(() =>
        dispatch({ type: "DELETE_FOLDER_REPORTS", payload: folder })
      );
    }
    return dispatch({
      type: "DELETE_FOLDER_ERROR_REPORTS",
      payload: "Sólo administradores"
    });
  },
  uploadFile: (route, file, load, currentUser) => {
    const listName = file.name.split(".");
    const name = listName.slice(0, listName.length - 1).join(".");
    const type = listName.pop();
    const fileObj = {
      name: name,
      type: type,
      author: currentUser.uid,
      date: new Date(),
      path: route.join("/")
    };
    firestore
      .collection("Informes")
      .add(fileObj)
      .then(fileRef => {
        const uploadTask = storage
          .ref(`Informes/${fileRef.id}.${type}`)
          .put(file);
        uploadTask.on(
          "state_changed",
          snapshot => {},
          error => {
            console.log(error);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(url => {
              load(fileRef);
              dispatch({
                type: "SET_FILES_REPORTS",
                payload: { ...fileObj, url: url }
              });
            });
          }
        );
      })
      .catch(err => {
        dispatch({ type: "UPLOAD_FILE_ERROR_REPORTS" }, err);
      });
  },
  moveFile: (currentUser, file, newPath) => {
    if (currentUser.admin) {
      return moveItem(file, newPath.join("/"))
        .then(() => dispatch({ type: "SET_FILES_REPORTS", payload: file }))
        .catch(err =>
          dispatch({
            type: "MOVE_FILE_ERROR_REPORTS",
            payload: err
          })
        );
    }
    return dispatch({
      type: "MOVE_FILE_ERROR_REPORTS",
      payload: "Sólo administradores"
    });
  },
  deleteFile: (currentUser, file) => {
    // Borrar archivo
    console.log("borrar archivo", file);
    if (currentUser.admin) {
      return deleteFile(file)
        .then(() => dispatch({ type: "DELETE_FILE_REPORTS", payload: file }))
        .catch(err =>
          dispatch({
            type: "DELETE_FILE_ERROR_REPORTS",
            payload: err
          })
        );
    }
    return dispatch({
      type: "DELETE_FILE_ERROR_REPORTS",
      payload: "Sólo administradores"
    });
  }
});
