import React from "react";
import {
  Flex,
  Box,
  Heading,
  CardContainer,
  Button,
  Divider,
  LoadingButton
} from "components/UI";
import EditUser from "./EditUser";
import AllUsers from "./AllUsers";
import CreateUser from "./CreateUser";

const ProfileView = ({
  currentUser,
  editUser,
  onEdit,
  fields,
  updateUser,
  mailError,
  passwordError,
  ...admin
}) => (
  /* ...rest = 
      users
      showing
      onShowUsers
      showUser
      setShowUser
      onNewUser
      newUser
      changeNewUser
      createUser
      deleteUser*/
  <CardContainer
    title={<Heading textAlign="center">Configuración</Heading>}
    width={1}
  >
    <Flex justifyContent="flex-end">
      <Box>
        <Heading>
          {currentUser.firstName} {currentUser.lastName}
        </Heading>
      </Box>
    </Flex>
    <Divider />
    <Flex justifyContent="center" mt={3}>
      <Box mr={2} my={[2, 0]}>
        {(currentUser.admin && admin.showing !== "editUser") ||
        (!currentUser.admin && editUser === null) ? (
          <Button onClick={onEdit}>Editar</Button>
        ) : (
          <Flex justifyContent="space-between">
            <Box mr={2}>
              <Button
                onClick={onEdit}
                shape="circle"
                icon="close"
                title="Cerrar"
                type="danger"
              />
            </Box>
            <Box ml={2}>
              <LoadingButton
                onClick={() => updateUser({ currentUser, ...editUser })}
                shape="circle"
                icon="check"
                title="Aceptar"
                type="primary"
              />
            </Box>
          </Flex>
        )}
      </Box>
      {currentUser.admin && admin.showing !== "editUser" ? (
        <Box my={[2, 0]}>
          {admin.showing !== "allUsers" && admin.showing !== "newUser" ? (
            <Button onClick={() => admin.setShowing("allUsers")}>
              Usuarios
            </Button>
          ) : (
            <LoadingButton
              onClick={() => admin.setShowing(null)}
              shape="circle"
              icon="close"
              title="Cerrar"
              type="danger"
            />
          )}
        </Box>
      ) : null}
    </Flex>
    {admin.showing === "editUser" ||
    (!currentUser.admin && editUser !== null) ? (
      <EditUser fields={fields} editUser={editUser} />
    ) : null}
    {currentUser.admin ? (
      <React.Fragment>
        {admin.showing === "allUsers" ? (
          <AllUsers
            currentUser={currentUser}
            users={admin.users}
            showing={admin.showing}
            setShowing={admin.setShowing}
            showUser={admin.showUser}
            setShowUser={admin.setShowUser}
            deleteUser={admin.deleteUser}
          />
        ) : null}
        {admin.showing === "newUser" ? (
          <CreateUser
            form={admin.newUser}
            setShowing={admin.setShowing}
            onChange={admin.changeNewUser}
            createUser={admin.createUser}
          />
        ) : null}
      </React.Fragment>
    ) : null}
  </CardContainer>
);

export default ProfileView;
