const downloadLink = url => {
  setTimeout(() => {
    const response = {
      file: url
    };
    window.open(response.file, "_blank");
  }, 100);
};

export default downloadLink;
