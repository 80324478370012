import React, { useState } from "react";
import { useUsers, useProfileState } from "utils";
import ProfileView from "./ProfileView";

const AdminContainer = ({
  currentUser,
  resetState,
  createUser,
  deleteUser,
  updateUser,
  fields
}) => {
  const mailSuccess = useProfileState("mailSuccess");
  const mailError = useProfileState("mailError");
  const passwordSuccess = useProfileState("passwordSuccess");
  const passwordError = useProfileState("passwordError");

  // Lista con todos los usuarios
  const [users] = useUsers(currentUser);

  // Para ver que configuracion se esta mostrando
  const [showing, setShowing] = useState(null);

  // Config de editar el currentUser
  const [editUser, setEdit] = useState({
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    mail: currentUser.email,
    changePassword: false,
    password: "",
    confirmPassword: "",
    notifications: currentUser.notifications
  });

  // Para ver y editar los otros usuarios
  const [showUser, setShow] = useState({ show: null, edit: null });

  // Crear Usuario
  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    mail: "",
    priority: 3,
    admin: false
  });

  const onEditClick = (field, value = null) => {
    setEdit({ ...editUser, [field]: value });
  };

  const editFields = fields(
    editUser,
    onEditClick,
    mailSuccess,
    mailError,
    passwordSuccess,
    passwordError
  );

  return (
    <ProfileView
      currentUser={currentUser}
      editUser={editUser}
      onEdit={() => {
        if (showing !== "editUser") {
          setShowing("editUser");
        } else {
          resetState();
          setShowing(null);
        }
      }}
      fields={editFields}
      users={users}
      showing={showing}
      setShowing={setShowing}
      showUser={showUser}
      setShowUser={(user, key) =>
        setShow({ ...showUser, [key]: showUser[key] === user ? null : user })
      }
      newUser={newUser}
      changeNewUser={(key, value) => setNewUser({ ...newUser, [key]: value })}
      createUser={() =>
        createUser(newUser, currentUser).then(success => {
          if (success) {
            setShowing("allUsers");
          }
        })
      }
      deleteUser={deleteUser}
      updateUser={updateUser}
    />
  );
};

export default AdminContainer;
