import React, { useState } from "react";
import { useProfileState } from "utils";
import ProfileView from "./ProfileView";

const ProfileContainer = ({ currentUser, resetState, updateUser, fields }) => {
  const mailSuccess = useProfileState("mailSuccess");
  const mailError = useProfileState("mailError");
  const passwordSuccess = useProfileState("passwordSuccess");
  const passwordError = useProfileState("passwordError");
  const [editUser, setEdit] = useState(null);
  const onEditClick = (value, field = null) => {
    setEdit(
      field === null
        ? {
            firstName: value.firstName,
            lastName: value.lastName,
            mail: value.mail,
            changePassword: false,
            currentPassword: "",
            password: "",
            confirmPassword: ""
          }
        : { ...editUser, [value]: field }
    );
  };
  const editFields = fields(
    editUser,
    onEditClick,
    mailSuccess,
    mailError,
    passwordSuccess,
    passwordError
  );
  return (
    <ProfileView
      currentUser={currentUser}
      editUser={editUser}
      onEdit={() => {
        if (editUser === null) {
          onEditClick(JSON.parse(JSON.stringify(currentUser)));
        } else {
          resetState();
          setEdit(null);
        }
      }}
      fields={editFields}
      updateUser={updateUser}
    />
  );
};

export default ProfileContainer;
