import { useCollection } from "react-firebase-hooks/firestore";
import { firestore } from "config";

export default user => {
  const [values, loading] = useCollection(firestore.collection("users"));
  return !values
    ? [values, loading]
    : user && user.admin
    ? [
        values.docs.reduce((obj, doc) => {
          obj[doc.id] = { uid: doc.id, ...doc.data() };
          return obj;
        }, {}),
        loading
      ]
    : [undefined, false];
};
