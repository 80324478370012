import React from "react";
import {
  CardContainer,
  Heading,
  Flex,
  Text,
  Radio,
  Hide,
  MyIcon,
  Box
} from "components/UI";
import RequestDates from "./RequestDates";
import EventsCalendar from "./EventsCalendar";
import UserPoints from "./BarChartView";

const titles = {
  calendar: "Calendario de reservas",
  request: "Pedir fechas",
  criteria: "Criterios de Priorización de Reserva",
  nights: "Ranking de Prioridad"
};

const subtitles = {
  calendar: [
    "Aquí podrás ver los nombres y fechas de quien",
    "ha utilizado o reservado la casa del campo"
  ],
  request: [
    "Aquí podrás realizar la solicitud de reserva",
    "de la casa del campo para una fecha determinada"
  ],
  criteria: [
    "Aquí podrás revisar los criterios que se acordaron para priorizar el uso de ",
    "la casa del campo si dos o más la solicitan para una misma fecha"
  ],
  nights: [
    "Aquí podrás ver el estado de prioridad de",
    "cada familia para el uso de la casa del campo"
  ]
};

const CalendarView = ({
  showing,
  setShowing,
  form,
  setValues,
  focusedInput,
  setFocused,
  today,
  onMonthSelect,
  calendarDate,
  pickMonthRef,
  createRequest,
  events,
  error,
  daySummary,
  setDaySummary,
  admins,
  windowSize
}) => (
  <React.Fragment>
    <Flex justifyContent="center" m={2}>
      <Radio.Group value={showing} onChange={e => setShowing(e.target.value)}>
        <Hide xs>
          <Radio.Button value="calendar">Calendario</Radio.Button>
          <Radio.Button value="request">Solicitud</Radio.Button>
          <Radio.Button value="criteria">Criterios</Radio.Button>
          <Radio.Button value="nights">Prioridad</Radio.Button>
        </Hide>
        <Hide sm md lg xl>
          <Radio.Button value="calendar">
            <MyIcon type="calendar" />
          </Radio.Button>
          <Radio.Button value="request">
            <MyIcon type="plus" />
          </Radio.Button>
          <Radio.Button value="criteria">
            <MyIcon type="info-circle" />
          </Radio.Button>
          <Radio.Button value="nights">
            <MyIcon type="bar-chart" />
          </Radio.Button>
        </Hide>
      </Radio.Group>
    </Flex>
    <CardContainer
      title={
        <React.Fragment>
          <Heading textAlign="center">{titles[showing]}</Heading>
          <Hide xs>
            <Text textAlign="center">{subtitles[showing][0]}</Text>
            <Text textAlign="center">{subtitles[showing][1]}</Text>
          </Hide>
        </React.Fragment>
      }
      width={1}
      pb={[6, 5]}
    >
      <Flex justifyContent="center">
        {showing === "calendar" ? (
          <EventsCalendar
            calendarDate={calendarDate}
            events={events}
            today={today}
            onMonthSelect={onMonthSelect}
            pickMonthRef={pickMonthRef}
            daySummary={daySummary}
            setDaySummary={setDaySummary}
          />
        ) : showing === "request" ? (
          <RequestDates
            today={today}
            form={form}
            setValues={setValues}
            focusedInput={focusedInput}
            setFocused={setFocused}
            createRequest={createRequest}
            error={error}
            windowSize={windowSize}
          />
        ) : showing === "nights" ? (
          <UserPoints admins={admins} />
        ) : (
          <Flex justifyContent="center" flexDirection="column">
            <Text.p>
              El criterio fundamental para priorizar el uso de la casa, en caso
              que dos o más coincidan en reservar una fecha, es asignarla
              siempre a quien la haya usado menos. Para eso el sistema llevará
              un registro acumulado de las Reservas de la casa, entendidas estas
              como la cantidad de noches de alojamiento que ha reservado cada
              grupo familiar. El sistema llevará el registro para cada uno de
              los siguientes grupos familiares:
            </Text.p>
            <Text.p>a. María Elena y familia</Text.p>
            <Text.p>b. Arturo s.j</Text.p>
            <Text.p>c. Pablo y familia</Text.p>
            <Text.p>d. Juan Andrés y familia</Text.p>
            <Text.p>
              El tata Arturo y la Mele tienen su pieza disponible siempre y no
              es necesario que reserven.
            </Text.p>
            <Text.p>
              De esta manera cualquier miembro de la familia podrá solicitar en
              esta página el uso de la casa, indicando la fecha de llegada y
              salida, cuántas de las 4 piezas utilizará y si está disponible
              para compartir la estadía en caso que queden piezas sin ocupar. El
              sistema enviará esta solicitud a todos y dará un plazo para que
              cualquier otro interesado en esa fecha, con menor registro de
              Reservas, haga también su solicitud. Trascurrido el plazo, el
              sistema confirmará la solicitud de quien tenga el menor registro
              de Reservas, contabilizará el número de noches reservadas en la
              cuenta de la respectiva familia y enviará el aviso
              correspondiente. En caso que, por haber indicado disposición a
              compartir la estadía, dos o más familias coincidan en determinadas
              fechas, el sistema contabilizará el número de noches compartidas
              ponderadas por 1/2 o 1/3 según sean dos o tres familias las que
              compartieron la casa. Una vez confirmada una reserva, nadie más
              puede solicitar las fechas reservadas.
            </Text.p>
            <Text.p>
              El plazo que dará el sistema a partir de la presentación de una
              solicitud de reserva para presentar nuevas solicitudes sobre una
              misma fecha será de hasta 30 días antes de la fecha de inicio de
              la reserva solicitada. Si la solicitud se presenta con menos de 30
              días a la fecha de inicio de la reserva, el plazo será hasta 7
              días antes de la fecha de inicio de la reserva solicitada. Si la
              solicitud se presenta con menos de 7 días a la fecha de inicio de
              la reserva, la confirmación de la solicitud será instantánea.
            </Text.p>
            <Text.p>
              El uso de la casa por el día, sin alojamiento, no requiere
              solicitar una reserva ni será registrado en Reservas. Sin embargo,
              se requiere que el interesado dé aviso a través del sistema en el
              botón Aviso de paseo por el día, el cual enviará un mail
              automático a todos. El sistema advertirá además si alguien tiene
              reservada la casa en esa fecha para que el solicitante, por
              deferencia, consulte a quien tiene la reserva si no hay
              inconveniente en ir por el día al campo.
            </Text.p>
          </Flex>
        )}
      </Flex>
    </CardContainer>
  </React.Fragment>
);
/*Toolbar({
              today,
              onMonthSelect,
              calendarDate,
              pickMonthRef
            })*/

export default CalendarView;
